import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import * as models from 'app/models';
import { DescriptionDialogComponent } from '../description-dialog/description-dialog.component';
import { HelperService, NotifierService } from 'app/services';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-subtasks-dialog',
  templateUrl: './subtasks-dialog.component.html',
  styleUrls: ['./subtasks-dialog.component.scss']
})
export class SubtasksDialogComponent implements OnInit {

  subtasks: Array<models.SubtaskDisplay> = [];

  constructor(
    private dialogRef: MatDialogRef<SubtasksDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private dialog: MatDialog
    , private helperService: HelperService
    , private notifierService: NotifierService
    , private firestore: AngularFirestore
  ) { 
    if(data) {
      let subtasks =  this.helperService.deepCopy(data.subtasks);
      this.subtasks = subtasks.map(i => {return {displayDescription: false, subtask: i}});
    }
  }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.subtasks, event.previousIndex, event.currentIndex);
  }

  add() {
    this.subtasks.push({
      displayDescription: false, 
      subtask: {
        id: this.firestore.createId(),
        description: '', 
        completed: false
      }
    });
  }

  more(subtask: models.SubtaskDisplay) {
    subtask.displayDescription = !subtask.displayDescription;
    // let dialog = this.dialog.open(DescriptionDialogComponent, {
    //   data: {description: subtask.description}
    // });

    // dialog.afterClosed().subscribe(result => {
    //   if(result != null) {
    //     subtask.description = result;
    //   }
    // });
  }

  remove(n: number){
    //this.notes.splice(this.notes.findIndex(i => i == n), 1);
    this.subtasks.splice(n, 1);
  }

  close() {
    let response = {
      accept: false
    }
    this.dialogRef.close(response);
  }

  save() {
    if(!this.verify()) return;

    let response = {
      accept: true,
      subtasks: this.subtasks.map(i => i.subtask)
    }
    this.dialogRef.close(response);
  }

  verify() {
    let emptySubtasks = this.subtasks.map(i => i.subtask).filter(i => i.title == '' || i.title == null);

    if(emptySubtasks.length > 0) {
      this.notifierService.error('Please fill out all subtasks');
      return false;
    }

    return true
  }
}
