import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Calendar, CalendarOptions, EventApi, EventClickArg, EventDropArg, EventInput, EventSourceInput } from '@fullcalendar/core';
import { EditRecurringEventsDialogComponent, EventAddComponent, EventAddFromLibraryDialogComponent, EventUpdateComponent, FilterCalendarDialogComponent, HarvestAddComponent, HarvestGroupDeleteComponent, RestrictionNotificationComponent, UsersAssignComponent } from 'app/dialogs';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { AppStorage } from 'app/services';
import * as equal from 'fast-deep-equal';

import * as models from 'app/models';
import * as services from 'app/services';
import * as viewmodels from 'app/viewmodels';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FullCalendarComponent, EventDef } from '@fullcalendar/angular';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!

import { forkJoin, Subscription } from 'rxjs';
import { FilterSectionComponent } from '../filter-section/filter-section.component';
import { LeaflogixSyncBtnComponent } from 'app/buttons/leaflogix-sync-btn/leaflogix-sync-btn.component';
import { Firestore, onSnapshot, Timestamp, Unsubscribe } from '@angular/fire/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import tippy from 'tippy.js';

@Component({
  selector: 'app-calendar-colGroup',
  templateUrl: './calendar-colGroup.component.html',
  styleUrls: ['./calendar-colGroup.component.scss'],
})
export class CalendarColGroupComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild(FilterSectionComponent) filterSectionComponent: FilterSectionComponent;
  @ViewChild(LeaflogixSyncBtnComponent) leaflogixSyncBtnComponent: LeaflogixSyncBtnComponent;
  calendarApi: Calendar;

  onlyMyTasks: boolean = false;
  //filterUserId: string = undefined;
  filterUserIds: string[];
  filterTeamId: string = undefined;
  filterTeamIds: string[];
  completeStatus: string = 'both';
  phases: string[] = [];
  tags: string[] = [];
  zoneIds: string[] = [];
  filteredHarvestPhasesByZone: viewmodels.HarvestPhases[];

  users: Array<models.User> = [];
  retrievedUsers: boolean = false;
  teams: Array<models.Team> = [];
  retrievedTeams: boolean = false;

  eventList?: Array<models.Event> = [];

  harvestIds: Array<string> = [];
  harvests: Array<models.Harvest> = [];
  groupIds: Array<string> = [];

  zones: Array<models.Zone> = [];

  collectionGroupSub: Unsubscribe;
  events: models.Event[] = [];

  viewType: string = 'dayGridMonth';
  viewStart: Date;
  viewEnd: Date;

  tippyInstance: any;
  displayTooltip: boolean;

  companySub: Subscription;

  get canSeeHarvestAdd(): boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.addHarvest);
  }

  get canSeeGroupAdd(): boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.addGroup);
  }

  get canSeeMassUserAssign(): boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.assignUsers);
  }

  get canSeeHarvestGroupDelete(): boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.addHarvest) ||
           this.claimsService.userRole?.permissions?.includes(models.Permissions.addGroup);
  }

  async canDragEvents(): Promise<boolean> {
    return (await this.claimsService.getRoleAwait())?.permissions?.includes(models.Permissions.editEvent);
  }

  get canSeeAddEventBtn(): boolean {
    return this.claimsService.userRole?.permissions?.includes(models.Permissions.addEvent);
  }

  get canSeeWeeklyListSlider(): boolean {
    if (this.viewType == 'listWeek' || this.viewType == 'dayGridWeek') {
      return true;
    }
    return false;
  }

  eventSubs: EventSubscription[] = [];
  get calendarEvents(): EventInput[] {
    this.eventSubs.forEach(list => {
      this.filterEventList(list);
    });

    return this.eventSubs.flatMap(i => i.events);
  }

  filterEventList(list: EventSubscription) {
    let events = list.allEvents;
    if(this.harvestIds != null){
      events = events.filter(i => i.type != 'harvests' || this.harvestIds.includes(i.parentId));
    }
    if(this.groupIds != null){
      events = events.filter(i => i.type != 'groups' || this.groupIds.includes(i.parentId));
    }

    if (this.onlyMyTasks) {
      events = events.filter(i =>
        (i.eventObj.assignedUserIds != null &&
          i.eventObj.assignedUserIds.includes(this.claimsService.currentUserId())) ||
        (i.eventObj.assignedTeamIds != null &&
          i.eventObj.assignedTeamIds.filter(value => this.filterTeamIds.includes(value)).length > 0)
      )
    }

    //filter by user
    if (this.filterUserIds != null && this.filterUserIds.length > 0) {
      events = events.filter(i =>
        (i.eventObj.assignedUserIds != null &&
          i.eventObj.assignedUserIds.filter(value => this.filterUserIds.includes(value)).length > 0) ||
        (i.eventObj.assignedTeamIds != null &&
          i.eventObj.assignedTeamIds.filter(value => this.filterTeamIds.includes(value)).length > 0)
      )
    }

    //filter by team
    if (this.filterTeamIds != null && this.filterTeamIds.length > 0) {
      events = events.filter(i =>
      (i.eventObj.assignedTeamIds != null &&
        i.eventObj.assignedTeamIds.filter(value => this.filterTeamIds.includes(value)).length > 0)
      )
    }

    //filter by phases
    if (this.phases != null && this.phases.length > 0) {
      events = events.filter(i => i.eventObj.isInGroup || i.eventObj.phase == null || i.eventObj.phase == '' || this.phases.includes(i.eventObj.phase))
    }

    //filter by tags
    if (this.tags != null && this.tags.length > 0) {
      events = events.filter(i => i.eventObj.tags != null && i.eventObj.tags.filter(value => this.tags.includes(value)).length > 0)
    }

    //filter by zone
    if (this.zoneIds != null && this.zoneIds.length > 0) {
      let foundHarvestPhases = this.filteredHarvestPhasesByZone.find(i => i.harvestId == list.id);
      if (foundHarvestPhases == null) {
        events = [];
      } else {
        events = events.filter(i => foundHarvestPhases.phases?.includes(i.eventObj.phase))
      }
    }

    //filter by completed status
    if (this.completeStatus != null) {
      switch (this.completeStatus) {
        case 'incomplete':
          events = events.filter(i => i.eventObj.completed == false)
          break;
        case 'complete':
          events = events.filter(i => i.eventObj.completed == true)
          break;
        case 'both':
        default:
          events = events;
      }
    }

    list.events = events;
  }

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin],
    dateClick: this.addEvent.bind(this),
    views: {
      dayGridMonth: { buttonText: this.deviceService.isMobile() ? 'm' : 'month' },
      dayGridWeek: {
        buttonText: this.deviceService.isMobile() ? 'w' : 'week',
        eventLimit: 20
      },
      listDay: { buttonText: this.deviceService.isMobile() ? 'd' : 'day' },
      //timeGridWeek: { buttonText: this.deviceService.isMobile() ? 'W3' : 'Week3' },
      listWeek: { buttonText: this.deviceService.isMobile() ? 'w' : 'week' }
    },
    headerToolbar: {
      left: 'title',
      //center: 'title',
      right: 'today dayGridMonth,listWeek,listDay prev,next'
    },
    eventClick: this.eventClick.bind(this),
    height: 'auto',
    //eventStartEditable: await this.canDragEvents(),
    eventDrop: this.eventDrop.bind(this),
    //eventSources: this.calendarEvents,
    eventOrder: this.eventOrder.bind(this),
    //eventContent: this.eventContent.bind(this),
    eventClassNames: this.eventClassName.bind(this),
    datesSet: this.datesSet.bind(this),
    eventDisplay: 'list-item',
    dayMaxEventRows: 12,
    //eventContent: this.eventContent.bind(this)
    eventTextColor: 'black',
    eventMouseEnter: this.onEventMouseEnter.bind(this),
    eventMouseLeave: (arg) => {
      if(this.tippyInstance){
        this.tippyInstance.destroy();
        this.tippyInstance = null;
      }
    },
  }

  makeTooltip(eventObject: any) {
    let name = eventObject.harvestName || eventObject.groupName;
    let description = eventObject.description;
    let _teams = this.helperService.concatToSentence(this.teams.filter(i => (eventObject.assignedTeamIds ?? []).includes(i.uid)).map(i => i.name));
    let _users = this.helperService.concatToSentence(this.users.filter(i => (eventObject.assignedUserIds ?? []).includes(i.id)).map(i => i.displayName));
    let tooltipContent = `<div style="padding: 10px; box-shadow: 0px 4px 15px -4px rgba(0,0,0,0.20); border-radius: 5px;"><div style="border-bottom: 1px solid #AAAAAA"><b>${eventObject.harvestName ? 'Harvest' : 'Group'}:</b> ${name}</div>`;
    
    if(_teams) {
      tooltipContent += `<div style='border-bottom: 1px solid #AAAAAA'><b>Event Description: </b>${description}</div>`;
      if(_users) {
        tooltipContent += `<div style='border-bottom: 1px solid #AAAAAA'><b>Team(s): </b>${_teams}</div>`
                        + `<div><b>User(s): </b>${_users}</div>`;
      } else {
        tooltipContent += `<div><b>Team(s): </b>${_teams}</div>`;
      }
    } else if (_users) {
      tooltipContent += `<div style='border-bottom: 1px solid #AAAAAA'><b>Event Description: </b>${description}</div>`
                      +`<div><b>User(s): </b>${_users}</div>`;
    } else {
      tooltipContent += `<div><b>Event Description: </b>${description}</div>`;
    }
    
    tooltipContent += `</div>`;

    return tooltipContent;
  }

  onEventMouseEnter(arg: any) {
    if(!this.displayTooltip || this.viewType == 'listWeek' || this.viewType == 'listDay') {
      return;
    }
    let _event = arg.event.extendedProps.eventObj;
    let tooltipContent = this.makeTooltip(_event);
    this.tippyInstance = tippy(arg.el, {
      content: tooltipContent,
      hideOnClick: true,
      allowHTML: true,
      onShow(instance) {
        instance.popper.style.backgroundColor = 'white';
        instance.popper.style.color = '#494949';
        instance.popper.style.fontSize = '14px';
        instance.popper.style.fontWeight = 'normal';
        instance.popper.style.borderRadius = '5px';
      },
      onHide(instance) {
        instance.popper.style.display = 'none';
      },
    })
  }

  gettingEvents: boolean = false;

  hasSetEvents: boolean = false;
  async setCalendarEvents(sub: EventSubscription = null) {
    this.gettingEvents = true;
    if (this.zoneIds != null && this.zoneIds.length > 0) {
      console.log('This shouldnt be happening a lot')
      this.filteredHarvestPhasesByZone = await this.harvestService.getHarvestsAndPhasesByZoneIds(this.zoneIds);
    }
    let allEvents = this.calendarEvents;
    let currentEvents = allEvents.filter(i => 
      i.date >= this.calendarComponent.getApi().view.activeStart &&
      i.date <= this.calendarComponent.getApi().view.activeEnd);
    
    // // Get the height of calendar container and set it so it doesn't shrink
    const calendarContainerHeight = this.helperService.getDivHeightByClass('calendar-container');
    this.helperService.setHeightByClass('calendar-container', calendarContainerHeight + 'px');

    //set events
    this.calendarOptions.events = currentEvents;

    //after events are set, we can remove the height variable on the calendar container
    await this.helperService.wait(10);
    this.helperService.removeHeightFromClass('calendar-container');

    //this.plotEvents(currentEvents);
    this.events = [...this.eventSubs.filter(i => i.eventList != null).map(i => i.eventList).flat()];
    this.gettingEvents = false;
  }

  plotEvents(events: EventInput[]) {
    let currentCalendar: EventApi[] = []
    if(this.calendarApi != null){
      currentCalendar = this.calendarApi.getEvents();
    }

    if(currentCalendar.length == 0) {
      this.calendarOptions.events = events;
    } else {
      //find current events to delete
      const eventIdsToPlot = events.map(i => i.id);
      const eventsToDelete = currentCalendar.filter(i => !eventIdsToPlot.includes(i.id));
  
      eventsToDelete.forEach(event => {
        event.remove();
      });

      // loop through each event in the current list
      for (let i = 0; i < events.length; i++) {
        const newEvent = this.helperService.deepCopy(events[i]);

        // find the corresponding event in the list, if it exists
        const currentEvent = currentCalendar.find(e => e.id === newEvent.id);

        // if the event isn't in the new list, delete it from the current list
        if (currentEvent == null) {
          this.calendarApi.addEvent(newEvent);
        } else {
          // let eventsHaveNotChanged = equal(newEvent.eventObj, currentEvent.title.extendedProps.eventObj);
          // let currentDate = currentEvent.start;
          // let newDate = new Date(newEvent.date.toLocaleString());
          // if(!eventsHaveNotChanged || !this.compareDates(currentDate, newDate)){
          if(!this.compareEvents(newEvent, currentEvent)){
            this.calendarEventService.updateEvent(currentEvent, newEvent.eventObj, this.users, this.teams, this.zones, this.harvests);
          }
        }
      }

      this.calendarApi.render();
    }
  }

  compareEvents(newEvent: EventInput, currentEvent: EventApi): boolean {
    let currentDate = currentEvent.start;
    let newDate = new Date(newEvent.date.toLocaleString());

    let currentClassName = currentEvent.classNames?.join(' ');

    const currentEventObj: models.Event = this.helperService.deepCopy(currentEvent.extendedProps.eventObj);
    delete currentEventObj.startDateTime;
    const newEventObj: models.Event = this.helperService.deepCopy(newEvent.eventObj);
    delete newEventObj.startDateTime;

    let objectsEqual = equal(newEventObj, currentEventObj);
    if(!objectsEqual){
      return false;
    }

    const currentBadge = this.getBadgeNumber(currentEventObj);
    const newBadge = this.getBadgeNumber(newEventObj);

    return currentDate.getFullYear() == newDate.getFullYear() && 
    currentDate.getMonth() == newDate.getMonth() && 
    currentDate.getDay() == newDate.getDay() &&
    (currentDate.getHours() == newDate.getHours() || newEvent.allDay) &&
    newEvent.allDay == currentEvent.allDay &&
    newEvent.title == currentEvent.title &&
    newEvent.className == currentClassName &&
    currentBadge == newBadge;// &&
    // newEventObj.tags == currentEventObj.tags &&
    // newEventObj.timeSpent == currentEventObj.timeSpent &&
    // newEventObj. == currentEventObj.timeSpent;
  }

  constructor(
    private claimsService: services.ClaimsService
    , private dialog: MatDialog
    , private helperService: services.HelperService
    , private appStorage: AppStorage
    , private deviceService: DeviceDetectorService
    , private eventService: services.EventService
    , private userService: services.UserService
    , private teamService: services.TeamService
    , private harvestService: services.HarvestService
    , private groupService: services.GroupService
    , private notifierService: services.NotifierService
    , private zoneService: services.ZoneService
    , private calendarService: services.CalendarService
    , private filterSetService: services.FilterSetService
    , private calendarEventService: services.CalendarEventService
    , private firestore: AngularFirestore
    , private companyService: services.CompanyService
    , private templateService: services.TemplateService
  ) { }

  async ngOnInit() {
    this.calendarOptions.eventStartEditable = await this.canDragEvents();

    this.displayTooltip = this.helperService.currentCompany.eventsTooltips ? this.helperService.currentCompany.eventsTooltips : false;

    this.companySub = this.helperService.getCurrentCompany().subscribe(data => {
      this.displayTooltip = data.eventsTooltips ? data.eventsTooltips : false;
    })
  }

  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();
  }

  ngOnDestroy() {
    if(this.collectionGroupSub != null){
      this.collectionGroupSub();
    }
    if(this.companySub != null){
      this.companySub.unsubscribe();
    }
  }

  async initialLoadFromFilter(result: any) {
    await this.helperService.wait(10);
    this.eventSubs = [];
    this.harvestIds = result.harvestIds;
    this.groupIds = result.groupIds;
    this.initialLoad();
  }

  async initialLoad() {
    let companyId = this.helperService.currentCompanyId;
    this.users = await this.userService.getUsersForCompanyWithCaching(companyId, true, true)
    this.retrievedUsers = true;

    this.teams = await this.getTeams()
    this.retrievedTeams = true;

    if(this.collectionGroupSub){
      this.collectionGroupSub();
    }

    this.loadColGroup();
  }

  loadColGroup() {
    this.gettingEvents = true;

    this.collectionGroupSub = onSnapshot(this.eventService.getColGroup(), (response) => {
      this.gettingEvents = true;
      this.eventSubs = this.eventSubs.filter(i => response.docs.map(j => j.id).includes(i.eventListId))
      response.forEach(element => {
        let dbEventObj: any = element.data();
        let list: Array<models.Event> = dbEventObj.list;

        let type: string = element.ref.parent.parent.parent.id;
        //type = type.substring(type.lastIndexOf('/'));

        let parentId: string = element.ref.parent.parent.id;
        //type = type.substring(type.lastIndexOf('/'));

        let eventSub: EventSubscription;
        eventSub = this.eventSubs.find(i => i.eventListId == element.id);

        let addEventSub: boolean = false;
        if(eventSub == null) {
          addEventSub = true;
          eventSub = {id: parentId, events: [], allEvents: []};
        }

        eventSub.eventListId = element.id;
        eventSub.eventList = list;

        let calendarEvents: Array<any> = []
        list.forEach((dbevent, index) => {
          if(dbevent.id == null) {
            dbevent.id = this.firestore.createId()
          }

          //set the color and completed value of the event
          let color: string = dbevent.color;
          let className: string = '';
          if (dbevent.completed) {
            className = 'completed'
          }
          if (dbevent.highPriority && !dbevent.completed) {
            className += ' high-priority';
          }
          if (type == 'groups') {
            className += ' task-group-event';
          }

          dbevent.isInGroup = type == 'groups';

          //get zone identifier
          let zoneIdentifier = null;
          if (type == 'harvests' && this.zones.length > 0) {
            let zoneIds = this.getZoneIds(parentId, dbevent.phase);
            let zones = this.zoneService.getAllTopLevel(this.zones, zoneIds);
            zoneIdentifier = zones.map(i => i.name).join(', ');
          }

          //get the title of the event
          let title: string = `${type == 'harvests' ? dbevent.harvestName : dbevent.groupName}${zoneIdentifier != null && zoneIdentifier != '' ? ' (' + zoneIdentifier + ') ' : ''}: ${dbevent.description}`;
          //if the assigned team ids is not empty or null
          if (dbevent.assignedTeamIds !== undefined && dbevent.assignedTeamIds.length !== 0) {
            title = `${title} - Teams: `;

            const teams = this.teams.filter(i => (dbevent.assignedTeamIds ?? []).includes(i.uid)).map(i => i.name);

            title += this.helperService.concatToSentence(teams);
          }
          //if the assigned user ids is not empty or null
          if (dbevent.assignedUserIds !== undefined && dbevent.assignedUserIds.length !== 0) {
            title = `${title} - Users: `;

            const users = this.users.filter(i => (dbevent.assignedUserIds ?? []).includes(i.id)).map(i => i.displayName);
            title += this.helperService.concatToSentence(users);
          }

          let event: EventInput = {
            id: dbevent.id,
            title: title,
            date: dbevent.startDateTime.toDate(),
            allDay: dbevent.anyTime !== false,
            color: color,
            className: className,
            eventObj: dbevent,
            type: type,
            parentId: parentId,
          }

          calendarEvents.push(event);
        });

        eventSub.events = calendarEvents;
        eventSub.allEvents = calendarEvents;
        eventSub.completedInitialGet = true;

        if(addEventSub){
          this.eventSubs.push(eventSub)
        }
        //this.setCalendarEvents(eventSub);
      })

      this.setCalendarEvents();
      //this.calendarOptions.events = calendarEvents;
    })
  }

  async getTeams(): Promise<models.Team[]> {
    let teams: models.Team[] = [];
    let teamResponse = await (await this.teamService.getAll().get()).toPromise()
    teamResponse.docs.forEach(doc => {
      let team: models.Team = doc.data();
      team.uid = doc.id;
      teams.push(team);
    })
    return teams;
  }

  onFilter(result) {
    this.harvestIds = result.harvestIds;
    this.groupIds = result.groupIds;
    this.zoneIds = result.zoneIds;
    this.phases = result.phases;
    this.filterTeamIds = result.teamIds;
    this.filterUserIds = result.userIds;
    this.tags = result.tags
    this.completeStatus = result.completeStatus;
    this.onlyMyTasks = result.onlyMyTasks;

    this.hasSetEvents = false;
    this.setCalendarEvents();
  }

  getZoneIds(id: string, phase: string) {
    let zoneIds: string[] = [];
    let harvest = this.harvests.find(i => i.uid == id);
    if (harvest == null) {
      return zoneIds;
    }
    let currentPhase = harvest.phases?.find(i => i.phaseName == phase);
    if (currentPhase?.strains == null) {
      return zoneIds;
    }
    currentPhase.strains.forEach(strain => {
      if (strain.zones == null) {
        return;
      }
      strain.zones.forEach(zone => {
        if (!zoneIds.includes(zone.zoneId)) {
          zoneIds.push(zone.zoneId);
        }
      });
    });

    return zoneIds;
  }

  async canAdd() {
    let harvestResponse = await this.harvestService.getAll(false).get().toPromise();
    if (this.helperService.company_freeVersion && harvestResponse.size >= this.appStorage.freeHarvests) {
      return false;
    }
  }

  addHarvest() {
    if (!this.canAdd()) {
      let restrictionDialog = this.dialog.open(RestrictionNotificationComponent, {
        data: 'harvestAdd'
      });
      return;
    }

    let dialogRef = this.dialog.open(HarvestAddComponent, {
      data: { addGroup: false }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      //reload list
      if (result.added) {
        this.harvestIds.push(...result.id);
        //need to get new group in filter
        this.filterSectionComponent.harvestsChanged(this.harvestIds);
        //this.compileHarvestSubscriptions();
        this.setCalendarEvents();
      }
      console.log(JSON.stringify(result));
    })
  }

  addedHarvests(dialogResult: any) {
    if(dialogResult.harvestIds && dialogResult.harvestIds.length > 0) {
      this.harvestIds.push(...dialogResult.harvestIds);
      this.filterSectionComponent.harvestsChanged(this.harvestIds);
      this.setCalendarEvents();
    }

    // if(dialogResult.linked && this.leaflogixSyncBtnComponent != null){
    //   this.leaflogixSyncBtnComponent.sync();
    // }
  }

  addGroup() {
    let dialogRef = this.dialog.open(HarvestAddComponent, {
      data: { addGroup: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      //reload list
      if (result.added) {
        this.groupIds.push(result.id);
        //need to get new group in filter
        this.filterSectionComponent.groupsChanged(this.groupIds);
        this.setCalendarEvents();
      }
      console.log(JSON.stringify(result));
    })
  }

  addEvent(arg: DateClickArg) {
    if(!this.claimsService.userRole?.permissions?.includes(models.Permissions.addEvent)){
      return;
    }

    let data: any = {}
    if (this.viewType == 'listDay') {
      data.date = this.viewStart;
    } else if (arg != null) {
      data.date = arg.date
    }

    if(data.date != null) {
      data.date.setHours(12);//default hour to noon
    }
    
    let dialogRef = this.dialog.open(EventAddComponent, {
      panelClass: 'med-width-dialog',
      data: data,
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe((result: viewmodels.EventAddResponse) => {
      this.processEventAddResponse(result);
    })
  }

  addEventFromLibrary() {
    let inObj: any = {

    }
    let dialogRef = this.dialog.open(EventAddFromLibraryDialogComponent, {
      data: inObj
    });

    dialogRef.afterClosed().subscribe((result: viewmodels.EventAddResponse) => {
      this.processEventAddResponse(result);
    })
  }

  async processEventAddResponse(result: viewmodels.EventAddResponse) {
    if (result && result.save) {
      //perform save
      let assignToGroup: boolean = result.assignToGroup;
      let harvest: models.Harvest = result.harvest;
      let group: models.Group = result.group;

      let events: Array<models.Event> = result.events;
      let dbEvents: Array<models.Event> = [];

      events.forEach(event => {
        let dbEvent: models.Event = event;

        dbEvent.color = assignToGroup ? group.color : harvest.color;

        if (assignToGroup) {
          delete dbEvent.harvestName;
          dbEvent.groupName = group.name;
        } else {
          delete dbEvent.groupName;
          dbEvent.harvestName = harvest.name;
        }

        dbEvents.push(dbEvent);
      });
      await this.eventService.add(assignToGroup, assignToGroup ? group.uid : harvest.uid, dbEvents);
    }
    console.log(JSON.stringify(result));
  }

  usersMassAssign() {
    this.dialog.open(UsersAssignComponent);
  }

  harvestGroupDelete() {
    let dialogRef = this.dialog.open(HarvestGroupDeleteComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let harvestIdsToRemove: string[] = result.harvestIds;
        let groupIdsToRemove: string[] = result.groupIds;

        this.harvestIds = this.harvestIds.filter(i => !harvestIdsToRemove.includes(i))
        this.groupIds = this.groupIds.filter(i => !groupIdsToRemove.includes(i))
        this.filterSectionComponent.harvestsChanged(this.harvestIds)
        this.filterSectionComponent.groupsChanged(this.groupIds)
        //this.load();
      }
    })
  }

  eventClick(arg: EventClickArg) {

    //remove all the popovers if there are any
    const elements = document.querySelectorAll('.fc-popover');
    elements.forEach(element => element.remove());
    
    let start = arg.event.start;
    let type = arg.event._def.extendedProps.type;
    let parentId = arg.event._def.extendedProps.parentId;
    let harvestId;
    let groupId;
    if (type == 'harvests') {
      harvestId = parentId;
    } else if (type == 'groups') {
      groupId = parentId;
    } else {
      return;
    }
    let assignToGroup: boolean = false;
    if (harvestId == null && groupId != null) {
      assignToGroup = true;
    }

    let foundSub: EventSubscription;
    foundSub = this.eventSubs.find(i => i.id == parentId);
    let data: any = {
      event: arg.event._def.extendedProps.eventObj,
      start: start,
      assignToGroup: assignToGroup,
      eventList: foundSub.eventList
    }

    if (assignToGroup) {
      data.groupId = groupId
    } else {
      data.harvestId = harvestId
    }

    let dialogRef = this.dialog.open(EventUpdateComponent, {
      width: '800px',
      data: data
    });

    dialogRef.afterClosed().subscribe(async (result: viewmodels.EventUpdateDialogResult) => {
      if (!result) {
        return;
      }
      if (result.save) {
        //perform save
        let dbEvent: models.DbEvent = {
          uid: foundSub.eventListId,
          list: result.eventList
        }
        await this.eventService.save(assignToGroup, foundSub.id, dbEvent);
        if(result.automaticallyCompletedEvent) {
          this.notifierService.success('All subtasks are complete, event has been automatically marked as completed');
        }
      }
      else if (result.delete) {
        let list: models.Event[] = result.eventList;

        result.eventIdsToDelete.forEach(id => {
          let index = list.findIndex(i => i.id == id);
  
          if (index > -1) {
            list.splice(index, 1);
          }
        });

        let dbEvent: models.DbEvent = {
          uid: foundSub.eventListId,
          list: list
        }
        await this.eventService.save(assignToGroup, foundSub.id, dbEvent);
      }
    })
  }

  async eventDrop(arg: EventDropArg) {
    arg.revert() // make sure the change happened in the database

    let event: models.Event = arg.event._def.extendedProps.eventObj
    let type = arg.event._def.extendedProps.type;
    let parentId = arg.event._def.extendedProps.parentId;
    let start = arg.event.start;

    //find if it is recurring event
    if (event.repeatedEventId != null && event.repeatedEventId != '') {

      let dialogRef = this.dialog.open(EditRecurringEventsDialogComponent, {
        autoFocus: true,
        disableClose: false
      });

      dialogRef.afterClosed().subscribe(async result => {
        //reload list
        if (result) {
          await this.updateEvent(event, result, start, type, parentId)
          //alert('save all now - ' + result);
        } else {
          arg.revert()
        }
        console.log(result);
      })
    } else {
      await this.updateEvent(event, 'one', start, type, parentId);
    }
  }

  async updateEvent(event: models.Event, recurringDescission: string, start: Date, type: any, parentId: string) {
    let harvestId;
    let groupId;
    if (type == 'harvests') {
      harvestId = parentId;
    } else if (type == 'groups') {
      groupId = parentId;
    } else {
      return;
    }
    let assignToGroup: boolean = false;
    if (harvestId == null && groupId != null) {
      assignToGroup = true;
    }

    let foundSub: EventSubscription;
    foundSub = this.eventSubs.find(i => i.id == parentId);

    let diff: number = Timestamp.fromDate(start).seconds - event.startDateTime.seconds;

    switch (recurringDescission) {
      case 'following':
        let applicableFollowingEvents = foundSub.eventList.filter(i => i.repeatedEventId == event.repeatedEventId &&
          i.startDateTime.seconds >= event.startDateTime.seconds);
        for (let i = 0; i < applicableFollowingEvents.length; i++) {
          const ev = applicableFollowingEvents[i];
          ev.startDateTime = new Timestamp(ev.startDateTime.seconds + diff, ev.startDateTime.nanoseconds)
        }
        break;
      case 'all':
        let applicableEvents = foundSub.eventList.filter(i => i.repeatedEventId == event.repeatedEventId);
        for (let i = 0; i < applicableEvents.length; i++) {
          const ev = applicableEvents[i];
          ev.startDateTime = new Timestamp(ev.startDateTime.seconds + diff, ev.startDateTime.nanoseconds)
        }
        break;
      case 'one':
      default:
        let foundEvent = foundSub.eventList.find(i => i.id == event.id);
        foundEvent.startDateTime = Timestamp.fromDate(start);
        break;
    }

    let dbEvent: models.DbEvent = {
      uid: foundSub.eventListId,
      list: foundSub.eventList
    }
    await this.eventService.save(assignToGroup, foundSub.id, dbEvent);
    this.notifierService.success('Successfully saved new task date');
  }

  eventOrder(eventA, eventB) {
    if (eventA.eventObj.highPriority && !eventB.eventObj.highPriority) {
      return -1;
    }
    if (!eventA.eventObj.highPriority && eventB.eventObj.highPriority) {
      return 1;
    }

    if (eventA.allDay && !eventB.allDay) {
      return 1;
    }
    if (!eventA.allDay && eventB.allDay) {
      return -1;
    }

    if (eventA.start < eventB.start) {
      return -1;
    } else if (eventA.start > eventB.start) {
      return 1;
    }

    let precA: number = eventA.precedence ? eventA.precedence : 0;
    let precB: number = eventB.precedence ? eventB.precedence : 0;

    if (precB < precA) {
      return -1;
    } else if (precB > precA) {
      return 1;
    }

    eventB.title.localeCompare(eventA.title)

    if (eventA.title < eventB.title) { return -1; }
    if (eventA.title > eventB.title) { return 1; }

    return 0;
  }

  eventClassName(arg: any) {
    let c = [];
    let event: EventDef = arg.event;
    let eventObj: models.Event = event.extendedProps.eventObj;
    if (eventObj.highPriority) {
      c.push('high-priority');
    }
    if (eventObj.completed) {
      c.push('completed');
    }
    if (eventObj.subtasks != null && eventObj.subtasks.length > 0) {
      c.push('subtasks');
    }

    const totalBadge = this.getBadgeNumber(eventObj);

    if (totalBadge > 0) {
      c.push('cal-badge');

      switch (totalBadge) {
        case (1):
          c.push('one');
          break;
        case (2):
          c.push('two');
          break;
        case (3):
          c.push('three');
          break;
        case (4):
          c.push('four');
          break;
        case (5):
          c.push('five');
          break;
        case (6):
          c.push('six');
          break;
        case (7):
          c.push('seven');
          break;
        case (8):
          c.push('eight');
          break;
        case (9):
          c.push('nine');
          break;
        default:
          c.push('plus');
          break;
      }
    }
    return c;
  }

  getBadgeNumber(event: models.Event) {
    let applicableEvents = event.discussions?.filter(i =>
      i.discussionType == models.DiscussionType.Note ||
      i.discussionType == models.DiscussionType.InitialNote ||
      i.discussionType == models.DiscussionType.AttachedFile) ?? [];

    let totalBadge = applicableEvents.length + (event.cloudAttachments?.length ?? 0);

    return totalBadge;
  }

  eventContent(arg: any) {
    let event: EventDef = arg.event;
    let title = event.title;
    if (event.extendedProps.eventObj.highPriority) {
      title = '<i style="font-weight: bold;" class="fa fa-exclamation"></i>  ' + event.title;
    }

    return { html: title };
  }

  datesSet(dateInfo) {
    this.viewType = dateInfo.view.type;
    this.viewStart = dateInfo.start;
    this.viewEnd = dateInfo.end;

    if (this.viewType == 'listWeek' && !this.weeklyListView) {
      this.viewType = 'dayGridWeek';
      this.calendarComponent.getApi().changeView('dayGridWeek');
    }
    this.setCalendarEvents();
  }

  weeklyListView: boolean = true;
  toggleListViewChange(newVal) {
    if (this.weeklyListView) {
      this.viewType = 'listWeek';
      this.calendarComponent.getApi().changeView('listWeek');
    } else {
      this.viewType = 'dayGridWeek';
      this.calendarComponent.getApi().changeView('dayGridWeek');
    }

    this.appStorage.weeklyListView = this.weeklyListView;
  }

}

interface EventSubscription {
  id: string;
  eventListId?: string;
  //sub: Subscription;
  events: EventInput[]; //filtered events - to display
  allEvents: EventInput[]; //all events in calendar format - unfiltered
  eventList?: Array<models.Event>; //all events in the model of what is in the system
  completedInitialGet?: boolean;
}